export const NAVIGATION = {
    // Authentications
    LOGIN: '/login',
    AUTH_TOKEN: '/authentication',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    LOGOUT: '/logout',
    
    // Main Screens
    DASHBOARD: '/',
    FOR_COLLABORATORS: '/for-collaborators',

    // Content Pages
    ABOUT_US: '/about-us',
    FOR_USERS: '/for-users',
    FOR_BUSINESSES: '/for-businesses',
    FOR_PARTNERS: '/for-partners',
    USE_CASES: '/use-cases',
    RESOURCES: '/resources',
    HOW_TO_CONNECT: '/connect',

    // Acount
    ACCOUNT: '/account',

    // Others
    ERROR_404: '/not-found',
    TERMS_OF_SERVICE: '/terms-of-service',
    PRIVACY_POLICY: '/privacy-policy',
    PAYMENT_POLICY: '/payment-policy',
    SHIPMENT_POLICY: '/shipment-policy',
    INSPECTION_POLICY: '/inspection-policy',
    RETURN_POLICY: '/return-policy',
    RIGHTS_PROTECTION_POLICY: '/rights-protection-policy',
    WEBSITE_OWNER: '/website-owner',
    CONTACT_US: '/contact-us',
    FAQS: '/faqs',
    KEYWORDS: '/keywords',
}