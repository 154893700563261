import React, { useState } from 'react';
import './ForCollaborators.scss';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter, Slider } from 'app/components';
import { Autoplay } from "swiper/modules";

import { motion } from "framer-motion";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const ForCollaborators = connect(mapStateToProps, actionCreators)(props => {

    const { locale_string } = props.pageInfo;
    const lang = props.pageInfo.display_lang;

    const [currentIndex, setCurrentIndex] = useState(0);

    const registration_steps = [
        {
            "title": "User registration",
            "description": "Click on the button “Collab With Us”, and register a LocaGo Web account",
            "img_url": "/public_assets/images/home/registration-section/registration-step-1.png",
        },
        {
            "title": "Log in",
            "description": "Log in LocaGo Web with registered account",
            "img_url": "/public_assets/images/home/registration-section/registration-step-2.png",
        },
        {
            "title": "Send refferal link to FnB owners",
            "description": "The referral link is in LocaGo Web for users, send it to the F&B owners you want to invite.",
            "img_url": "/public_assets/images/home/registration-section/registration-step-3.png",
        },
    ]

    function renderRegistrationSteps(list) {
        if (!list || list.length === 0) return null;

        let steps = [];
        list.forEach((item, index) => {
            steps.push(
                <div className={`registration-step-item ${index === currentIndex ? 'active' : ''}`} key={'registrationStep_' + index}>
                    <div className="step-number"><span>{index+1}</span><div className="step-number-line"></div></div>
                    <div className="step-content">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                </div>
            )
        })

        return steps;
    }
    
    return (
        <ScreenWithNavBarAndFooter className="page-for-collaborators">
            <header className="page-header content-page-header page-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/cta-section/cta-section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-5">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ opacity: 0, scale: 2 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Refer FnB business owners to our program and get rewarded with up to <span className="text-primary">1M points</span></motion.h2>
                                <motion.div
                                    initial={{ x: 200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="mt-4"
                                >
                                    <a href="#" target="_blank" className="btn btn-primary btn-rounded px-5">Become our collaborator</a>
                                </motion.div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src="/public_assets/images/home/cta-section/cta-image.png" alt="" className="img-fluid w-100"></img>
                        </div>
                    </div>
                </div>
            </header>
            <section className="page-section collab-signup-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/partner-section/partner-section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Sign up to be a referral partner and reach out to countless FnB establishments</motion.h2>
                    </div>
                    <div className="sec-content">
                        <div className="row align-items-center">
                            <div className="col-md-6 ps-md-5">
                                {renderRegistrationSteps(registration_steps)}
                            </div>
                            <div className="col-md-6">
                                <Slider
                                    className="registration-img-slider mb-5 mb-md-0"
                                    list={registration_steps}
                                    renderItem={(item, index) => (
                                        <img src={item.img_url} alt="" key={'registrationImgSlider_' + index} className="img-fluid w-100"></img>
                                    )}
                                    overrideConfig={{
                                        modules: [Autoplay],
                                        slidesPerView: 1,
                                        autoplay: {
                                            delay: 5000
                                        },
                                    }}
                                    indexChangeCallback={index => { setCurrentIndex(index) }}
                                />
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <a href="#" target="_blank" className="btn btn-primary btn-rounded px-5">Collab With Us</a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="social" className="page-section social-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/cta-section/cta-section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.div
                            initial={{ x: 200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="mb-5"
                        >
                            <img src="/public_assets/images/footer-logo.png" alt="" width={175} height={65} className="mb-4"></img>
                            <p><b>Address:</b> 18 Sin Ming Lane, #07-13, Midview City, Singapore (573960)</p>
                            <p><b>Email:</b> contact@locafnb.com</p>
                        </motion.div>
                        <div className="d-flex flex-row justify-content-center gap-1 gap-md-5 navbar-social-link">
                            <a href="https://x.com/locago_official" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/x-twitter.png" alt=""></img>
                            </a>
                            <a href="https://t.me/locagocommunity" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/telegram.png" alt=""></img>
                            </a>
                            <a href="https://youtube.com/@locago" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/youtube.png" alt=""></img>
                            </a>
                            <a href="https://www.facebook.com/LocaGo2024" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/facebook.png" alt=""></img>
                            </a>
                            <a href="https://www.tiktok.com/@locagoofficial" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/tiktok.png" alt=""></img>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    ForCollaborators
};