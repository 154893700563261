import React, { useEffect, useState } from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    const [navbarScroll, setNavbarScroll] = useState(false);

    const targetDate = '2024-10-31T00:00:00.000+00:00';
    const countDownDate = new Date(targetDate).getTime();

    useEffect(() => {
        document.addEventListener('scroll', handlePageScroll)

        return () => {
            document.removeEventListener('scroll', handlePageScroll)
        }
    }, [])

    function handlePageScroll(e) {
        // console.log('pageScroll', window.scrollY);
        if (window.scrollY > 70) {
            setNavbarScroll(true);
        } else {
            setNavbarScroll(false);
        }

    }

    return (
        <nav className={`navbar navbar-expand-lg navbar-dark fixed-top top-navbar ${navbarScroll ? 'scroll' : ''}`}>
            <div className="container-xl">
                <button className="navbar-toggler ms-3 order-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-1" to={NAVIGATION.DASHBOARD}>
                    <img src="/public_assets/images/header-logo.svg" alt="" height={40}></img>
                </Link>
                {
                    countDownDate - new Date().getTime() >= 0
                    ? <></>
                    : <>
                        <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto me-auto pt-3 pt-md-0 navbar-center">
                                <li className="nav-item">
                                    <NavLink to={NAVIGATION.DASHBOARD} className="nav-link">{text.top_nav_bar.home[lang]}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <a href="#about" className="nav-link">{text.top_nav_bar.about_us[lang]}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#future" className="nav-link">{text.top_nav_bar.partnership[lang]}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#features" className="nav-link">{text.top_nav_bar.features[lang]}</a>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={NAVIGATION.HOW_TO_CONNECT} className="nav-link">{text.top_nav_bar.how_to_connect[lang]}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <a href="/public_assets/LocaChain-Whitepaper-V1.0.pdf" target="_blank" className="nav-link">{text.top_nav_bar.whitepaper[lang]}</a>
                                </li>
                                <li className="nav-item mobile mt-3">
                                    <a href="https://explorer.locachain.io/" target="_blank" className="btn btn-primary">{text.top_nav_bar.explorer[lang]}</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="navbar-nav ms-3 order-2 order-lg-4 desktop">
                            <li className="nav-item">
                                <a href="https://explorer.locachain.io/" target="_blank" className="btn btn-primary">{text.top_nav_bar.explorer[lang]}</a>
                            </li>
                        </ul>
                    </>
                }
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)((props) => {
	const { pageInfo } = props;
	const lang = pageInfo.display_lang;

    const targetDate = '2024-10-31T00:00:00.000+00:00';
    const countDownDate = new Date(targetDate).getTime();

	return (
        <>
        {
            countDownDate - new Date().getTime() >= 0
            ? <></>
            : <section id="social" className="page-section social-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Join the LocaChain Movement</h2>
                        <p className="sec-description">
                            LocaChain is your gateway to the future of blockchain technology, offering real-world solutions to businesses and students alike. Whether you're looking to tokenize assets, track products, or issue vouchers, LocaChain has the tools you need to succeed.
                        </p>
                        <div className="d-flex flex-row justify-content-center gap-1 gap-md-5 navbar-social-link mt-5">
                            <a href="https://x.com/locago_official" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/x-twitter.png" alt=""></img>
                            </a>
                            <a href="https://t.me/locagocommunity" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/telegram.png" alt=""></img>
                            </a>
                            <a href="https://youtube.com/@locago" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/youtube.png" alt=""></img>
                            </a>
                            <a href="https://www.facebook.com/LocaGo2024" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/facebook.png" alt=""></img>
                            </a>
                            <a href="https://www.tiktok.com/@locagoofficial" target="_blank" className="btn btn-icon p-0">
                                <img src="/public_assets/images/home/social-section/tiktok.png" alt=""></img>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        }
		<footer className="site-footer">
			<div className="container-xl">
				<div id="copyright" className="site-copyright">
					<div className="row">
						<div className="col-md-6 text-center text-md-start mb-3 mb-md-0 fw-semibold">
							&copy; 2023 LocaMos Global AG.
							All rights reserved.
						</div>
						<div className="col-md-6 text-center text-md-end">
                            {
                                countDownDate - new Date().getTime() >= 0
                                ? <></>
                                : <>
                                    <a href="/public_assets/LocaChain-Whitepaper-V1.0.pdf" target="_blank" className="me-3">{text.top_nav_bar.whitepaper[lang]}</a>
                                    <a href="https://explorer.locachain.io/" target="_blank">{text.top_nav_bar.explorer[lang]}</a>
                                </>
                            }
							{/* <a href={'https://locago.tech' + NAVIGATION.PRIVACY_POLICY} className="me-4">{text.footer.links.privacy_policy[lang]}</a> */}
							{/* <a href={'https://locago.tech' + NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</a> */}
						</div>
					</div>
				</div>
			</div>
		</footer>
        </>
	);
});

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}