import React, { useState, useEffect, useRef } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter, Slider, CountdownTimer } from 'app/components';
import { Autoplay } from "swiper/modules";

import { decodeHtmlContent } from "utils/AppHelper";

import { motion } from "framer-motion";

import { homepageService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}


const Dashboard3 = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;
	const { locale_string } = pageInfo;

    const videoEl = useRef(null);

    const [videoSupport, setVideoSupport] = useState('UNDETECTED');

    useEffect(() => {
        console.log('Hello');
        
        if (videoEl && videoEl.current) {
            let canWEBM = videoEl.current.canPlayType('video/webm');
            let canMOV = videoEl.current.canPlayType('video/quicktime');
            
            console.log('WebM Support', canWEBM);
            console.log('MOV Support', canMOV);
            
            if ( canMOV === 'maybe' || canMOV === 'probably' ) {
                setVideoSupport('MOV');
            } else if ( canWEBM === 'maybe' || canWEBM === 'probably' ) {
                setVideoSupport('WEBM');
            } else {
                setVideoSupport('GIF');
            }
        }

        return () => {
        }
    }, [])

    function renderLocaChainCube(type) {
        console.log(type);
        
        switch (type) {
            case 'MOV':
                return (
                    <motion.video
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        className="img-fluid w-100" autoPlay={true} muted={true} loop={true} playsInline={true}
                        poster="/public_assets/images/home/home-header/3d.png">
                        <source src="/public_assets/images/home/home-header/3d.mov" type="video/quicktime"></source>
                    </motion.video>
                )
            case 'WEBM':
                return (
                    <motion.video
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        className="img-fluid w-100" autoPlay={true} muted={true} loop={true} playsInline={true}
                        poster="/public_assets/images/home/home-header/3d.png">
                        <source src="/public_assets/images/home/home-header/3d.webm" type="video/webm"></source>
                    </motion.video>
                )

            case 'GIF':
                return(
                    <motion.img
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        src="/public_assets/images/home/home-header/3d.gif" alt="" className="img-fluid w-100"
                    ></motion.img>
                )
            
            case 'UNDETECTED':
            default:
                return(
                    <motion.img
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        src="/public_assets/images/home/home-header/3d.png" alt="" className="img-fluid w-100"
                    ></motion.img>
                )
        }
    }
    
 
    return (
        <ScreenWithNavBarAndFooter className="page-home">
            <section className="page-section home-header">
                <div className="section-bg d-flex justify-content-center align-items-end align-items-md-center w-100" style={{ backgroundImage: 'url(/public_assets/images/home/home-header/home-header-bg.jpg)' }}>
                    <div className="container-fluid p-0">
                        <div className="row justify-content-end">
                            <div className="col-md-7">
                                {/* <motion.video
                                    initial={{ opacity: 0, scale: 3 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    ref={videoEl}
                                    className="img-fluid w-100" autoPlay={true} muted={true} loop={true} playsInline={true}>
                                    <source src="/public_assets/images/home/home-header/3d.mov" type="video/quicktime"></source>
                                    <source src="/public_assets/images/home/home-header/3d.webm" type="video/webm"></source>
                                </motion.video> */}
                                <motion.video
                                    ref={videoEl}
                                    className="d-none">
                                </motion.video>
                                {renderLocaChainCube(videoSupport)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="sec-header text-center text-md-start">
                        <motion.h1
                            initial={{ opacity: 0, scale: 2 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-big-title"
                        ><span className="text-primary">LocaChain</span> Mainnet is coming!</motion.h1>
                        <motion.h2
                            initial={{ opacity: 0, scale: 2 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Bringing Blockchain to the Real World</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description ms-0 d-none"
                        >
                            LocaChain is designed to bridge the gap between digital technology and real-world applications. Our mission is to tokenize and implement blockchain into real-world assets like Real Estate, Bonds, Contract, Fine Art, Voucher, E-Commerce, etc. Making them more secure, transparent, and accessible. From product origin tracking to asset tokenization, LocaChain provides a robust platform for businesses and individuals to harness the power of blockchain in everyday life.
                        </motion.p>
                        <motion.div
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="mt-5"
                        >
                            <CountdownTimer targetDate={Date.parse('2024-10-31T00:00:00.000+00:00')} />
                        </motion.div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})


const Dashboard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;
	const { locale_string } = pageInfo;

    const videoEl = useRef(null);

    const [videoSupport, setVideoSupport] = useState('UNDETECTED');

    const targetDate = '2024-10-31T00:00:00.000+00:00';
    const countDownDate = new Date(targetDate).getTime();

    useEffect(() => {
        console.log('Hello');
        
        if (videoEl && videoEl.current) {
            let canWEBM = videoEl.current.canPlayType('video/webm');
            let canMOV = videoEl.current.canPlayType('video/quicktime');
            
            console.log('WebM Support', canWEBM);
            console.log('MOV Support', canMOV);
            
            if ( canMOV === 'maybe' || canMOV === 'probably' ) {
                setVideoSupport('MOV');
            } else if ( canWEBM === 'maybe' || canWEBM === 'probably' ) {
                setVideoSupport('WEBM');
            } else {
                setVideoSupport('GIF');
            }
        }

        return () => {
        }
    }, [])

    function renderLocaChainCube(type) {
        console.log(type);
        
        switch (type) {
            case 'MOV':
                return (
                    <motion.video
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        className="img-fluid w-100" autoPlay={true} muted={true} loop={true} playsInline={true}
                        poster="/public_assets/images/home/home-header/3d.png">
                        <source src="/public_assets/images/home/home-header/3d.mov" type="video/quicktime"></source>
                    </motion.video>
                )
            case 'WEBM':
                return (
                    <motion.video
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        className="img-fluid w-100" autoPlay={true} muted={true} loop={true} playsInline={true}
                        poster="/public_assets/images/home/home-header/3d.png">
                        <source src="/public_assets/images/home/home-header/3d.webm" type="video/webm"></source>
                    </motion.video>
                )

            case 'GIF':
                return(
                    <motion.img
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        src="/public_assets/images/home/home-header/3d.gif" alt="" className="img-fluid w-100"
                    ></motion.img>
                )
            
            case 'UNDETECTED':
            default:
                return(
                    <motion.img
                        initial={{ opacity: 0, scale: 3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        src="/public_assets/images/home/home-header/3d.png" alt="" className="img-fluid w-100"
                    ></motion.img>
                )
        }
    }

    if (countDownDate - new Date().getTime() >= 0) {
        return (
            <ScreenWithNavBarAndFooter className="page-home">
                <section className="page-section home-header">
                    <div className="section-bg d-flex justify-content-center align-items-end align-items-md-center w-100" style={{ backgroundImage: 'url(/public_assets/images/home/home-header/home-header-bg.jpg)' }}>
                        <div className="container-fluid p-0">
                            <div className="row justify-content-end">
                                <div className="col-md-7">
                                    {/* <motion.video
                                        initial={{ opacity: 0, scale: 3 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        ref={videoEl}
                                        className="img-fluid w-100" autoPlay={true} muted={true} loop={true} playsInline={true}>
                                        <source src="/public_assets/images/home/home-header/3d.mov" type="video/quicktime"></source>
                                        <source src="/public_assets/images/home/home-header/3d.webm" type="video/webm"></source>
                                    </motion.video> */}
                                    <motion.video
                                        ref={videoEl}
                                        className="d-none">
                                    </motion.video>
                                    {renderLocaChainCube(videoSupport)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-xl">
                        <div className="sec-header text-center text-md-start">
                            <motion.h1
                                initial={{ opacity: 0, scale: 2 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-big-title"
                            ><span className="text-primary">LocaChain</span> Mainnet is coming!</motion.h1>
                            <motion.h2
                                initial={{ opacity: 0, scale: 2 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-title"
                            >Bringing Blockchain to the Real World</motion.h2>
                            <motion.p
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-description ms-0 d-none"
                            >
                                LocaChain, is a blockchain network designed to securely digitize and manage real-world assets. With a robust and scalable infrastructure, LocaChain enables applications for diverse sectors, from real estate and bonds to e-commerce. Leveraging BNB Application Sidechain, LocaChain combines high transaction speeds with low costs, making it a suitable platform for decentralized applications (DApps) focused on practical asset management and efficient digital trade.<br />
                                <br />
                                LocaChain offers a suite of features tailored for digital ownership transfer, supply chain transparency, and e-commerce integration. This platform empowers businesses, institutions, and individuals to harness blockchain technology in managing, transferring, and trading assets seamlessly.
                            </motion.p>
                            <motion.div
                                initial={{ x: -200, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="mt-5"
                            >
                                <CountdownTimer targetDate={Date.parse(targetDate)} />
                            </motion.div>
                        </div>
                    </div>
                </section>
            </ScreenWithNavBarAndFooter>
        )
    } else {
        return (
            <ScreenWithNavBarAndFooter className="page-home">
                <section className="page-section home-header">
                    <div className="section-bg d-flex justify-content-center align-items-end align-items-md-center w-100" style={{ backgroundImage: 'url(/public_assets/images/home/home-header/home-header-bg.jpg)' }}>
                        <div className="container-fluid p-0">
                            <div className="row justify-content-end">
                                <div className="col-9 col-md-5">
                                    {/* <motion.img
                                        initial={{ opacity: 0, scale: 3 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        src="/public_assets/images/home/home-header/3d-locachain.gif" alt="" className="img-fluid w-100 opacity-50"
                                    ></motion.img> */}
                                    <motion.video
                                        ref={videoEl}
                                        className="d-none">
                                    </motion.video>
                                    {renderLocaChainCube(videoSupport)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-xl">
                        <div className="sec-header">
                            <motion.h1
                                initial={{ opacity: 0, scale: 2 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-big-title"
                            >Welcome to <span className="text-primary">LocaChain</span></motion.h1>
                            <motion.h2
                                initial={{ opacity: 0, scale: 2 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-title"
                            >Bringing Blockchain to the Real World</motion.h2>
                            <motion.p
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-description ms-0"
                            >
                                LocaChain, is a blockchain network designed to securely digitize and manage real-world assets. With a robust and scalable infrastructure, LocaChain enables applications for diverse sectors, from real estate and bonds to e-commerce. Leveraging BNB Application Sidechain, LocaChain combines high transaction speeds with low costs, making it a suitable platform for decentralized applications (DApps) focused on practical asset management and efficient digital trade.<br />
                                <br />
                                LocaChain offers a suite of features tailored for digital ownership transfer, supply chain transparency, and e-commerce integration. This platform empowers businesses, institutions, and individuals to harness blockchain technology in managing, transferring, and trading assets seamlessly.
                            </motion.p>
                            <motion.div
                                initial={{ x: 200, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="mt-5"
                            >
                                <a href="#about" className="btn btn-primary btn-rounded px-5 mb-3 mb-md-0 me-md-3">Learn More</a>
                                <a href="https://explorer.locachain.io/" target="_blank" className="btn btn-outline-primary btn-rounded">Blockchain Explorer</a>
                            </motion.div>
                        </div>
                    </div>
                </section>
                <section id="about" className="page-section about-section">
                    <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/about-section/about-section-wave.svg)' }}></div>
                    <div className="container-xl">
                        <div className="sec-header text-center">
                            <motion.h2
                                initial={{ x: -200, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-title mb-1"
                            >Unlock the Power of <br className="desktop" /><span className="text-primary">Blockchain for Real-World Assets</span></motion.h2>
                            <motion.p
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-description"
                            >
                                LocaChain is more than just a digital network. It&apos;s a <b>complete ecosystem</b> that offers solutions for various real-world challenges
                            </motion.p>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-money-bill-trend-up fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Efficient Transactions and Trading</h3>
                                        <p>Conduct fast, secure, and low-cost transactions within the LocaChain ecosystem, whether you're trading vouchers, tokenized assets, or digital goods. Our platform is optimized for real-world business applications, ensuring smooth and transparent operations.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-grid-2-plus fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>DApp Development</h3>
                                        <p>LocaChain supports an environment for decentralized application (DApp) development, enabling businesses and developers to create real-world blockchain applications across various sectors.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-arrows-turn-right fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Multisend Token</h3>
                                        <p>LocaChain integrates with TrustKeys (a blockchain company from Singapore) to facilitate simultaneous multi-token transfers, allowing users to efficiently distribute tokens to multiple recipients.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-shipping-fast fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Product Traceability</h3>
                                        <p>Ensure product authenticity and transparency with our product origin tracking solution. LocaChain allows businesses to track the entire lifecycle of their products, providing consumers with verifiable information from the point of origin to the end purchase. This ensures the reliability and authenticity of goods across various industries.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-cash-register fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Crypto Payment Gateway</h3>
                                        <p>LocaChain supports integrated crypto payment gateways, enabling businesses to accept cryptocurrency payments and optimizing global transactions, particularly in a rapidly digitizing environment.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-thumbs-up fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>SocialFi Integration</h3>
                                        <p>Integrate SocialFi features on LocaChain to boost user engagement by enabling social interactions, rewards, and community-driven financial activities within the platform.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-tickets fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Voucher System</h3>
                                        <p>LocaChain provides a voucher solution tailored for FnB businesses or SMEs. Using blockchain technology, merchants can issue digital vouchers that are secure, traceable, and redeemable with ease. This system reduces fraud, improves customer engagement, and opens new marketing channels.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-hexagon-vertical-nft-slanted fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Tokenizing Real-World Assets</h3>
                                        <p>Convert physical assets such as real estate, artwork, and collectibles into digital tokens (NFTs), making them tradable and accessible globally. Asset tokenization unlocks liquidity, expands markets, and offers new opportunities for businesses and individuals alike.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-magnifying-glass-chart fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>NFT Marketplace</h3>
                                        <p>The platform integrates an NFT marketplace, allowing users to trade digital assets using LCG as the main token and LCC as the gas fee, enabling businesses to capitalize on the potential of the NFT market.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-cart-plus fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>E-commerce and Auction</h3>
                                        <p>LocaChain supports e-commerce and auction platforms, enabling businesses to list, sell, and manage products transparently on the blockchain, enhancing transaction credibility and reliability.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-vault fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Token Staking</h3>
                                        <p>Users can stake tokens to earn rewards and share transaction fees within the ecosystem, incentivizing participation and supporting the long-term growth of LocaChain network.</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-item"
                                >
                                    <div className="about-item-icon"><i className="fa-thin fa-bridge-circle-check fa-3x"></i></div>
                                    <div className="about-content">
                                        <h3>Bridge to BSC</h3>
                                        <p>LocaChain offers a bridge to facilitate seamless transfers between LocaChain and Binance Smart Chain (BSC), expanding interoperability and providing users access to decentralized financial services.</p>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="future" className="page-section future-section pb-0">
                    <div className="container-xl">
                        <div className="row align-items-end">
                            <div className="col-md-6">
                                <div className="sec-header">
                                    <motion.p
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                        className="badge rounded-pill border border-success sec-header-badge"
                                    >Supporting the Future<span className="dot-animated-wrapper ms-2"><span className="dot-animated"><span className="dot bg-success"></span><span className="dot-anim bg-success"></span></span></span></motion.p>
                                    <motion.h2
                                        initial={{ x: -200, opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                        className="sec-title"
                                    >Empowering Students <br className="desktop" />and Innovators</motion.h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="/public_assets/images/home/future-section/city.svg" alt="" className="img-fluid w-100"></img>
                            </div>
                        </div>
                    </div>
                    <div className="future-content-wrapper">
                        <div className="container-xl">
                            <div className="sec-header">
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description ms-0"
                                >
                                    LocaChain is not just about business solutions. It&apos;s about empowering the next generation of blockchain innovators. We support university and college students to learn how to apply blockchain technology to real-world challenges.
                                </motion.p>
                            </div>
                            <div className="sec-content text-center">
                                <div className="row justify-content-end">
                                    <div className="col-md-5 me-md-5">
                                        <div className="future-item">
                                            <div className="future-item-number text-primary">1</div>
                                            <div className="future-content">
                                                <h3>Educational Programs <br className="desktop" />and Partnerships</h3>
                                                <p>We collaborate with academic institutions to provide students with hands-on experience, helping them build the skills they need to innovate in industries like finance, supply chain, retail, and more.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="future-item">
                                            <div className="future-item-number text-primary">2</div>
                                            <div className="future-content">
                                                <h3>Problem Solving <br className="desktop" />with Blockchain</h3>
                                                <p>Students learn how blockchain can enhance transparency, improve security, and create new digital ownership models, giving them the skills to innovate in the rapidly evolving digital landscape.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="features" className="page-section features-section">
                    <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/features-section/features-section-bg.jpg)' }}></div>
                    <div className="container-xl">
                        <div className="sec-header text-center">
                            <motion.h2
                                initial={{ x: -200, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-title"
                            >Key Features of <span className="text-primary">LocaChain</span></motion.h2>
                        </div>
                        <div className="sec-content text-center">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="feature-item">
                                        <div className="feature-item-icon text-primary"><i className="fa-thin fa-chart-network fa-3x"></i></div>
                                        <div className="feature-content">
                                            <h3>Built on BEP20 Network</h3>
                                            <p>LocaChain operates on the highly scalable and secure BEP20 network, providing low-cost, efficient transactions while ensuring security and flexibility for businesses and users.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature-item">
                                        <div className="feature-item-icon text-primary"><i className="fa-thin fa-hexagon-vertical-nft-slanted fa-3x"></i></div>
                                        <div className="feature-content">
                                            <h3>Real-World Asset Tokenization</h3>
                                            <p>Whether it's issuing digital vouchers for F&B businesses or tokenizing high-value assets like real estate, LocaChain provides a practical platform for businesses to digitize and trade their goods.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature-item">
                                        <div className="feature-item-icon text-primary"><i className="fa-thin fa-shipping-fast fa-3x"></i></div>
                                        <div className="feature-content">
                                            <h3>Product Origin Tracking</h3>
                                            <p>Blockchain-powered origin tracking ensures that every step of your product&apos;s journey is verified, offering your customers complete transparency and building trust in your brand.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature-item">
                                        <div className="feature-item-icon text-primary"><i className="fa-thin fa-person-running-fast fa-3x"></i></div>
                                        <div className="feature-content">
                                            <h3>Educational Support for Students</h3>
                                            <p>We&apos;re preparing the next generation of innovators by offering hands-on blockchain learning experiences and fostering real-world problem-solving.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section id="join" className="page-section join-section">
                    <div className="section-bg bg-primary"></div>
                    <div className="container-xl">
                        <div className="sec-header text-center">
                            <motion.h2
                                initial={{ x: -200, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-title"
                            >Join the LocaChain Movement</motion.h2>
                            <motion.p
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-description"
                            >
                                LocaChain is your gateway to the future of blockchain technology, offering real-world solutions to businesses and students alike. Whether you're looking to tokenize assets, track products, or issue vouchers, LocaChain has the tools you need to succeed.
                            </motion.p>
                            <motion.div
                                initial={{ x: 200, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="mt-5"
                            >
                                <a href="#" className="btn btn-primary btn-rounded px-5 mb-3 mb-md-0 me-md-3">Learn More</a>
                                <a href="#" className="btn btn-outline-primary btn-rounded">Blockchain Explorer</a>
                            </motion.div>
                        </div>
                    </div>
                </section> */}
            </ScreenWithNavBarAndFooter>
        )
    }
})

const PartnerSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const partners_row_1 = [
        {
            "name": "Seagull Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/seagull-coffee.png",
        },
        {
            "name": "Nhà Hàng Cái Mâm",
            "img_url": "/public_assets/images/home/partner-section/logo/bep-cai-mam.png",
        },
        {
            "name": "Nhà Hàng Cơm Xưa",
            "img_url": "/public_assets/images/home/partner-section/logo/com-xua.png",
        },
        {
            "name": "Liberty Central",
            "img_url": "/public_assets/images/home/partner-section/logo/liberty-central.png",
        },
        {
            "name": "Chevalier",
            "img_url": "/public_assets/images/home/partner-section/logo/chevalier.png",
        },
        {
            "name": "Chef Dzung Buffet",
            "img_url": "/public_assets/images/home/partner-section/logo/chef-dzung.png",
        },
        {
            "name": "NH Vị An",
            "img_url": "/public_assets/images/home/partner-section/logo/vi-an.png",
        },
        {
            "name": "Khuê Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/le-jardin.png",
        },
        {
            "name": "IL Divo",
            "img_url": "/public_assets/images/home/partner-section/logo/il-divo.png",
        },
        {
            "name": "Hà Nội Corner",
            "img_url": "/public_assets/images/home/partner-section/logo/ha-noi-corner.png",
        },
        {
            "name": "Ngon Garden",
            "img_url": "/public_assets/images/home/partner-section/logo/ngon-garden.png",
        },
        {
            "name": "Runam Bistro",
            "img_url": "/public_assets/images/home/partner-section/logo/runam.png",
        },
        {
            "name": "Mixue",
            "img_url": "/public_assets/images/home/partner-section/logo/mixue.png",
        },
        {
            "name": "BaMi SaMi",
            "img_url": "/public_assets/images/home/partner-section/logo/bami-sami.png",
        },
        {
            "name": "T-Dessert",
            "img_url": "/public_assets/images/home/partner-section/logo/t-dessert.png",
        },
        {
            "name": "Cà phê Nấu",
            "img_url": "/public_assets/images/home/partner-section/logo/nau-ca-phe.png",
        },
        {
            "name": "An Nam Quán",
            "img_url": "/public_assets/images/home/partner-section/logo/an-nam-quan.png",
        },
        {
            "name": "Vista Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/the-cliff.png",
        },
        {
            "name": "Temple Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/the-temple.png",
        },
        {
            "name": "Hải sản Hằng Loan",
            "img_url": "/public_assets/images/home/partner-section/logo/hai-san-hang-loan.png",
        },
        {
            "name": "Cafe Cardinal",
            "img_url": "/public_assets/images/home/partner-section/logo/cafe-cardinal.png",
        },
    ]

    const partners_row_2 = [
        {
            "name": "Sky 36",
            "img_url": "/public_assets/images/home/partner-section/logo/sky36.png",
        },
        {
            "name": "Xofa Cafe & Bistro",
            "img_url": "/public_assets/images/home/partner-section/logo/xofa.jpg",
        },
        {
            "name": "Lofita Tea & Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/lofita.png",
        },
        {
            "name": "Sorae Restaurant Lounge",
            "img_url": "/public_assets/images/home/partner-section/logo/sorae.png",
        },
        {
            "name": "Grand Palace",
            "img_url": "/public_assets/images/home/partner-section/logo/grand-palace.png",
        },
        {
            "name": "Nhà Hàng Quá Ngon",
            "img_url": "/public_assets/images/home/partner-section/logo/qua-ngon.png",
        },
        {
            "name": "Phố 79",
            "img_url": "/public_assets/images/home/partner-section/logo/pho-79.png",
        },
        {
            "name": "Hotel Nikko Saigon",
            "img_url": "/public_assets/images/home/partner-section/logo/nikko.png",
        },
        {
            "name": "Nhà hàng Pao Quán",
            "img_url": "/public_assets/images/home/partner-section/logo/pao.png",
        },
        {
            "name": "Nhà Hàng 04",
            "img_url": "/public_assets/images/home/partner-section/logo/hai-san-04.png",
        },
        {
            "name": "Phở Mong",
            "img_url": "/public_assets/images/home/partner-section/logo/pho-mong.png",
        },
        {
            "name": "Bếp Việt, Hàn Vi Quán",
            "img_url": "/public_assets/images/home/partner-section/logo/bep-viet-han-vi-quan.png",
        },
        {
            "name": "Home 6",
            "img_url": "/public_assets/images/home/partner-section/logo/home6.png",
        },
        {
            "name": "Aha Cafe",
            "img_url": "/public_assets/images/home/partner-section/logo/aha.png",
        },
        {
            "name": "C.O.C Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/coc.png",
        },
        {
            "name": "Cua Ngon Cà Mau",
            "img_url": "/public_assets/images/home/partner-section/logo/cua.png",
        },
        {
            "name": "Nhà Hàng Cá Hồi Song Nhi Sa Pa",
            "img_url": "/public_assets/images/home/partner-section/logo/song-nhi-sa-pa.png",
        },
        {
            "name": "Ohana Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/ohana.png",
        },
        {
            "name": "Sline Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/sline-coffee.png",
        },
        {
            "name": "Nhà Hàng Vườn Quê",
            "img_url": "/public_assets/images/home/partner-section/logo/vuon-que.png",
        },
        {
            "name": "Hệ thống Nhà hàng chay Mộc Nhiên - Chi nhánh Thủ Đức",
            "img_url": "/public_assets/images/home/partner-section/logo/moc-nhien-chay.png",
        },
        {
            "name": "Hà Tôm Hùm",
            "img_url": "/public_assets/images/home/partner-section/logo/ha-hai-san.png",
        },
        {
            "name": "Đậu Má Mix - Đậu Nành và Rau Má - Trần Quốc Thảo",
            "img_url": "/public_assets/images/home/partner-section/logo/dau-ma-mix.png",
        },
        {
            "name": "PAMI 01",
            "img_url": "/public_assets/images/home/partner-section/logo/pami.png",
        },
    ]

    return (
        <section id="partner" className="page-section partner-section">
            <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/partner-section/partner-section-bg.jpg)' }}></div>
            <div className="container-xl">
                <div className="sec-header text-center">
                    <motion.h2
                        initial={{ x: -200, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-title"
                    >Our Strategic Partners</motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-description"
                    >
                        Partnering with trusted allies to deliver sustainable value
                    </motion.p>
                </div>
            </div>
            <div className="sec-content">
                <Slider
                    className="partner-img-slider mb-3"
                    list={partners_row_1}
                    renderItem={(item, index) => (
                        <img src={item.img_url} alt={item.name} key={'partnerImgSlider_' + index} className="img-fluid w-100"></img>
                    )}
                    overrideConfig={{
                        modules: [Autoplay],
                        allowTouchMove: false,
                        slidesPerView: 8.5,
                        slidesPerGroup: 9,
                        autoplay: {
                            delay: 5000
                        },
                        centerInsufficientSlides: true
                    }}
                />

                <Slider
                    className="partner-img-slider"
                    list={partners_row_2}
                    renderItem={(item, index) => (
                        <img src={item.img_url} alt={item.name} key={'partnerImgSlider_' + index} className="img-fluid w-100"></img>
                    )}
                    overrideConfig={{
                        modules: [Autoplay],
                        allowTouchMove: false,
                        slidesPerView: 8.5,
                        slidesPerGroup: 9,
                        autoplay: {
                            delay: 5000
                        },
                        centerInsufficientSlides: true
                    }}
                />
            </div>
            <div className="container-xl">
                <div className="marketpartner-wrapper">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Our <span className="text-primary">Market Development</span> Partners</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Collaborating with reputable partners to provide the best value for customers
                        </motion.p>
                    </div>
                    <div className="sec-content text-center">
                        <Slider
                            className="partner-img-slider"
                            list={[...partners_row_2, ...partners_row_2].reverse()}
                            renderItem={(item, index) => (
                                <img src={item.img_url} alt={item.name} key={'partnerImgSlider_' + index} className="img-fluid w-100"></img>
                            )}
                            overrideConfig={{
                                modules: [Autoplay],
                                allowTouchMove: false,
                                slidesPerView: 9,
                                slidesPerGroup: 9,
                                autoplay: {
                                    delay: 5000
                                },
                                centerInsufficientSlides: true
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
})

const NewsSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const news = [
        {
            "title": "[Yahoo] LocaMos Global AG Officially Inaugurates New Headquarters in Zug, Switzerland, Bridging MarTech and Blockchain Innovation",
            "description": "LocaMos Global AG, an emerging name in the technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://finance.yahoo.com/news/locamos-global-ag-officially-inaugurates-034100325.html",
            "img_url": "https://s.yimg.com/ny/api/res/1.2/wCXsAxlezV.JLsLmkuKnmQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTY4MQ--/https://media.zenfs.com/en/newsfile_64/adb3aa3e19ddfa3700b678348d18581f",
        },
        {
            "title": "[Business Insider] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://markets.businessinsider.com/news/stocks/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain-1032786035",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
        {
            "title": "[Benzinga] LocaMos Global AG commits to applying technology to address real-life challenges. The company offers a range of tools that help attract and engage customers through high-experiential features to support physical businesses, creating smoother business operations and growth.",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.benzinga.com/pressreleases/23/11/35636290/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain",
            "img_url": "/public_assets/images/news/news-2.jpg",
        },
        {
            "title": "[News Max] LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland",
            "description": "LocaMos Global AG, a technology company based in Zug, Switzerland, recently established, is making waves by combining two crucial facets in the technology industry: MarTech (Marketing Technology) and Blockchain. MarTech emphasizes utilizing technology to optimize and enhance marketing campaigns, while Blockchain has the potential to transform many sectors through its security and transparency in transactions.",
            "url": "https://pr.newsmax.com/article/LocaMos-Global-AG-Pioneering-a-Revolutionary-Blend-of-MarTech-and-Blockchain?storyId=6549e967aabbe7000889a161&fbclid=IwAR2GzfuyxswWFZHM5aLdlhJKoWBRjTR4aCwjGiU1DqcqJf5NNIxUdbXUSOk_aem_AehULbt_gCLI7P0HxUPS64lhOmWMIdh46HdHz3cLs3a-NmaEkM0gYbla8wừycQujJh8",
            "img_url": "https://storage.googleapis.com/mmstudio-images/gallery/AhvOgFGA4jcj9Xtx9NZIpIwF4Vw2/89112505-1699329968-1.jpg",
        },
        {
            "title": "[TGAM] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.theglobeandmail.com/investing/markets/markets-news/MarketersMEDIA%20Newswire/21772309/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain/?fbclid=IwAR2gZL6arEordyYiLCe4KFjHIgOq2Tl6uJJjBauzNabt9qAy2barC64eymg_aem_Aehjv52c61JvXo8gw0HebSzgI5HKXPePgq0BPU-ueX52dFqE_r7yiH5vjJbDtxAdx7g",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
    ]

    return (
        <section id="news" className={`page-section news-section ${props.className !== '' ? props.className : ''}`}>
            <div className="container-xl">
                <div className="sec-header text-center">
                    <motion.h2
                        initial={{ x: -200, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-title"
                    ><span className="text-primary">The Press</span> Talks About Us</motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-description"
                    >
                        LocaGo is praised by magazines for its creativity and effectiveness
                    </motion.p>
                </div>
                <div className="sec-content">
                    <Slider
                        className="news-slider"
                        list={news}
                        renderItem={(item, index) => (
                            <a href={item.url} target="_blank" className="article">
                                <div className="article-image">
                                    <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="article-info">
                                    <h4 className="article-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                    <p className="article-description" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.description) }}></p>
                                </div>
                            </a>
                        )}
                        overrideConfig={{
                            slidesPerView: 1,
                            breakpoints: {
                                768: {
                                    slidesPerView: 3
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
})

export {
    Dashboard,
    NewsSection
}