import { useEffect, useState } from 'react';
import "./CountdownTimer.scss";

const useCountdown = (targetDate) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(
		countDownDate - new Date().getTime()
	);

	useEffect(() => {		
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const days_text = days < 10
		? days < 0
			? '00'
			: '0' + days.toString()
		: days;

	const hours = Math.floor(
		(countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	const hours_text = hours < 10
		? hours < 0
			? '00'
			: '0' + hours.toString()
		: hours;

	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const minutes_text = minutes < 10
		? minutes < 0
			? '00'
			: '0' + minutes.toString()
		: minutes;

	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
	const seconds_text = seconds < 10
		? seconds < 0
			? '00'
			: '0' + seconds.toString()
		: seconds;

	return [days_text, hours_text, minutes_text, seconds_text];
};

const DateTimeDisplay = ({ value, type, isDanger }) => {
	return (
		<div className={isDanger ? 'counter' : 'counter'}>
			<p className="counter-number text-primary">{value}</p>
			<p className="counter-label">{type}</p>
		</div>
	);
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
	return (
		<>
		<div className="countdown-timer-wrapper">
			<div className="countdown-timer-bg">
				<div className="countdown-timer">
					<DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
					<span className="text-primary">:</span>
					<DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
					<span className="text-primary">:</span>
					<DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
					<span className="text-primary">:</span>
					<DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
				</div>
			</div>
			<p className="sec-description countdown-timer-description text-center mt-3">
				Prepare to join the Revolution!
			</p>
		</div>
		</>
	);
};

const CountdownTimer = ({ targetDate }) => {
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	if (days + hours + minutes + seconds <= 0) {
		return <></>;
	} else {
		return (
			<ShowCounter
				days={days}
				hours={hours}
				minutes={minutes}
				seconds={seconds}
			/>
		);
	}
};

export default CountdownTimer;
